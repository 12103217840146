<!--
 * @Author: jiang
 * @Date: 2021-07-14 16:43:21
 * @Description:
-->
<template>
  <div class="wrap">

    <div class="info">
      <div class="g-mr-10">
        <span>待验收: </span>
        <span class="num">{{infoData.wait_check_count}}</span>
      </div>
      <div>
        <span>待验收: </span>
        <span class="num">{{infoData.building}}</span>
      </div>
      <el-date-picker
        class="year"
        style="width: 150px;"
        v-model="year"
        type="year"
        value-format="yyyy"
        format="yyyy 年"
        placeholder="选择年"
        @change="fetchData"
      ></el-date-picker>
    </div>
    <div
      ref="chart"
      class="chart"
    ></div>
  </div>
</template>

<script>
import { getProjectStatistics } from '@/api/project'
export default {
  data() {
    return {
      year: this.$tools.dayjs().subtract(1, 'year').format('YYYY'),
      chart: null,
      infoData: {},
    }
  },
  mounted() {
    this.init()
    this.fetchData()
  },
  methods: {
    fetchData() {
      console.log(this.year)
      const year = this.year
      /**
       * wait_check_count int 待验收项目数量
       * building int 在建项目数量
       * normal int 正常进行项目数量
       * orange int 橙色警告项目数量
       * yellow int 黄色警告项目数量
       * total int 项目总数量
       */
      getProjectStatistics(year).then(res => {
        this.infoData = res
        const data = [
          { name: '正常进行', value: res.normal },
          { name: '黄色预警', value: res.orange },
          { name: '橙色预警', value: res.yellow },
        ]
        this.update(data)
      })
    },
    init() {
      this.chart = this.$echarts.init(this.$refs.chart)
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '5%',
          left: 'center',
        },
        color: ['#04A37E', '#FFBE22', '#FA8C16'],
        series: [
          {
            name: '项目状态',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '46%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            data: [],
          },
        ],
      }

      this.chart.setOption(option)
    },
    update(data) {
      const option = {
        series: [{ data }],
      }

      this.chart.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .info {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 50px;
    color: #747e8f;

    .num {
      color: #2b2d42;
    }

    .year {
      margin-left: auto;
    }
  }

  .chart {
    flex: 1;
    min-height: 0;
  }
}
</style>
