<!--
 * @Author: jiang
 * @Date: 2021-07-14 16:17:23
 * @Description:
-->
<template>
  <el-container style="height: 100%; padding: 0 15px 15px; overflow: hidden;">
    <el-header class="g-bg g-mb-15 header">
      <div class="title">内蒙古自治区中央预算内投资农业建设项目</div>
    </el-header>
    <el-container style="overflow: hidden;">
      <el-aside
        class="g-bg g-mr-15"
        style="flex: 1;"
      >
        <statistics-chart></statistics-chart>
      </el-aside>
      <el-main
        class="g-bg"
        style="flex: 2;"
      >
        <b-section-scroll>

          <template slot="header">
            <!-- <div>
            <el-form
              :model="searchData"
              inline
            >
              <el-form-item>
                <el-date-picker
                  type="year"
                  value-format="yyyy"
                  placeholder="选择年"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select placeholder="行业"></el-select>
              </el-form-item>
              <el-form-item>
                <el-select placeholder="建设单位"></el-select>
              </el-form-item>
              <el-form-item>
                <el-select placeholder="建设状态"></el-select>
              </el-form-item>
              <el-form-item>
                <el-select placeholder="预警状态"></el-select>
              </el-form-item>
            </el-form>
          </div> -->
          </template>

          <div
            v-if="list.length === 0"
            class="g-empty"
          ></div>
          <el-row
            style="padding: 15px;"
            v-else
            :gutter="20"
          >
            <el-col
              style="margin-bottom: 20px;"
              :span="12"
              v-for="item in list"
              :key="item.id"
            >
              <div
                class="list-item"
                @click="onNav(item)"
              >
                <div class="name g-text-ellipsis">{{ item.name }}</div>
                <div class="ff">
                  <el-tag
                    class="g-mr-10 g-mb-10"
                    type="primary"
                    effect="plain"
                  >{{ item.build_department }}</el-tag>
                  <el-tag
                    class="g-mr-10 g-mb-10"
                    type="primary"
                    effect="plain"
                  >{{ item.industry }}</el-tag>
                  <el-tag
                    class="g-mr-10 g-mb-10"
                    type="primary"
                    effect="plain"
                  >{{ item.year }}</el-tag>
                </div>
                <div class="status-box">
                  <div class="status">
                    <span>{{ item.progress_state + '' | dict('projectWarningStatusDict') }}</span>
                    <span v-if="item.comment">({{ item.comment }})</span>
                  </div>
                  <div
                    class="fill"
                    v-if="item.fill_in_state === '0'"
                  >未完成本月调度数据填报'</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </b-section-scroll>

      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import StatisticsChart from './components/statistics-chart.vue'
import BSectionScroll from '@/components/section/scroll'
import {
  getProjectStatistics,
  getProjectIndustryList,
  getProjectDepartmentList,
  getProjectPage,
} from '@/api/project'
export default {
  components: {
    StatisticsChart,
    BSectionScroll,
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      getProjectStatistics()
      getProjectIndustryList()
      getProjectDepartmentList()
      getProjectPage().then(res => {
        this.list = res.data
      })
    },
    onNav(row) {
      this.$router.push({ name: 'project/info', params: { id: row.id } })
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #2b2d42;
  }
}

.list-item {
  padding: 20px;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: rgba($color: #dfe3e8, $alpha: 0.45);
  }

  .name {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #2b2d42;
  }

  .status-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .status {
      font-size: 12px;
      color: #fa8c16;
    }

    .fill {
      font-size: 12px;
      color: #747e8f;
    }
  }
}
</style>
